.Checkbox {
  $border-color: #ccc;
  $checked-bg-color: #699b2c;
  $checked-border-color: #699b2c; // Border-färg när markerad (kan vara samma som bakgrundsfärgen)
  $checkmark-color: #fff; // Färg på bocken

  display: flex;
  align-items: center;


  input[type="checkbox"] {
    margin: 0 0 0 0;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    border-radius: 4px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    outline: none; // För att undvika kantlinjer vid fokus

    &:checked {
      background-color: $checked-bg-color;
      border-color: $checked-border-color;

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid $checkmark-color;
        border-width: 0 2.5px 2.5px 0;
        transform: rotate(45deg);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  label {
    padding: 4px 0 0 5px;
    font-size: 12px;
  }
}
