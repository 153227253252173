.DatePicker {
  display: flex;

  .header {
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .header-month {
      display: flex;
      gap: 10px;
    }

    .header-add {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  //react-datepicker__day react-datepicker__day--009 react-datepicker__day--selected{
  //react-datepicker__day react-datepicker__day--025 react-datepicker__day--holidays
  //react-datepicker__day react-datepicker__day--030 react-datepicker__day--keyboard-selected
  .react-datepicker__day {
    //background-color: red !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__day:hover {
    background-color: rgba(184, 207, 105, 0.2);
  }

  .react-datepicker__day--selected,
  .react-datepicker__week-number--keyboard-selected,
  .react-datepicker__day--selected:hover {
    background-color: rgb(105, 155, 44);
    color: #fff !important;
  }

  .react-datepicker__day--holidays,
  .react-datepicker__day--holidays:hover {
    background-color: #ff6803;
    color: #fff !important;
  }

  .react-datepicker-wrapper {
    //height: 20px;

    input {
      //width: 100%;
      width: inherit;
      font-size: 12px !important;
      padding: 6px 6px;
      border: 1px solid #ccc;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: #495057;
    }

  }

  .custom-week-number-input {
    width: 110px;
    border: 1px solid #ccc;
    padding: 6px 6px;
    background-color: white;
    font-size: 12px !important;
    cursor: pointer;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }

  .react-datepicker__calendar-icon {
    width: 12px !important;
    height: 12px !important;
  }

  .react-datepicker__week-number--selected {
    background-color: #fff;
    color: #ccc;
  }

  select {
    padding: 0 10px;
  }

  .icon {
    font-size: 14px;
    height: 27.6px;
    padding: 0 4px 0 3px !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ccc;
    color: #333;
  }
}

