.TurnoverInfo {


  .row {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .item:first-child {
      //background-color: #0baccc;
    }

    .item {
      width: 200px;
      text-align: center;

      .Label {
        justify-content: center;
      }inv_per_hour

      div:first-child {
        font-family: 'Open Sans';
        text-transform: uppercase;
        color: #444;
        margin-bottom: 5px;
      }

      div:last-child {
        font-weight: bold;
        font-size: 20px;
        color: #555;
      }
    }
  }
}