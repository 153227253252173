.DateStepper {
  display: flex;
  align-items: center;

  /* Disable text selection */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */

  .stepper-left {
    position: relative;
    margin-right: 5px;
    padding: 3px;
    cursor: pointer;
  }

  .stepper-right {
    margin-left: 5px;
    padding: 3px;
    cursor: pointer;
  }
}