.XlnzDialog {
  position: fixed;

  top: 50%;
  left: 50%;
  //-ms-transform: translateY(-50%);
  transform: translate(-50%, -50%);

  z-index: 2999;

  width: max-content;
  max-width: 100vw;
  max-height: 100vh;
  //overflow-x: hidden;
  //overflow-y: auto;
  //right: 0;
  //right: -100%;
  //transition: right 0.5s;

  display: flex;
  flex-direction: column;
  //align-items: center;
  //
  //max-width: 100%;

  .dialog-header {
    display: flex;
    align-items: center;

    background-color: #fff;
    padding: 0 15px;
    border: 1px solid gray;
    border-radius: 5px 5px 0 0;
    //border-bottom: none;

    cursor: move;

    :first-child {
      flex-grow: 1
    }

    :last-child {
      padding: 5px 10px;
      cursor: pointer;
      //background-color: red;
    }
  }

  .dialog-content {
    background-color: #fff;
    padding: 15px;
    border: 1px solid gray;
    border-bottom: none;
    //border-radius: 0 0 5px 5px;

    //max-width: 100%;
    overflow-x: auto;

    .EditForm {
      padding: 0;
      border: none;
    }
  }

  .dialog-buttons {
    background-color: #fff;
    border: 1px solid gray;
    border-top: none;
    border-radius: 0 0 5px 5px;

    padding: 0 15px 15px 15px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .divider {
      flex-grow: 1;
    }

    .left-buttons {
      display: flex;
      padding-top: 10px;
      gap: 10px;


      .p-button {
        margin-right: 15px;
      }
    }

    .right-buttons {
      display: flex;
      padding-top: 10px;
      gap: 10px;

      //.p-button, .ExtraMenu {
      //  margin-left: 15px;
      //}
    }

  }

  @media screen and (max-width: 999px) {
    .dialog-header {
      display: flex;
      align-items: center;

      background-color: #fff;
      padding: 0 15px;
      border-top: 0px solid gray;
      border-radius: 0;
    }

  }

}
