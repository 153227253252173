$BORDER_COLOR: #dee2e6;
$HOVER_BG_COLOR: #E9ECEF;
$mousex: 0;
$mousey: 0;


.ContextMenu {
  position: fixed;
  z-index: 1000;

  font-size: 12px;
  background-color: #fff;
  border: 1px solid $BORDER_COLOR;
  line-height: 30px;

  //--mouse-x: 0px;
  //--mouse-y: 0px;
  $mouseX: var(--mouse-x);
  $mouseY: var(--mouse-y);
  transform: translateX(Min($mouseX, calc(100vw - 100%))) translateY(Min($mouseY, calc(100vh - 100%)));

  .context-menu-item {
    cursor: pointer;
    padding: 5px 10px;
  }

  .context-menu-item:hover {
    background-color: $HOVER_BG_COLOR;
  }
}