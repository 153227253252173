.MonthStepper {
  display: flex;
  align-items: center;

  /* Disable text selection */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */

  .stepper-left {
    margin-right: 5px;
    padding: 3px;
    cursor: pointer;
  }

  .stepper-right {
    margin-left: 5px;
    padding: 3px;
    cursor: pointer;
  }


  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {

  }

  .react-datepicker__month-text {
    padding: 10px 7px;
  }

  .react-datepicker__month-text:hover {
    background-color: rgba(184, 207, 105, 0.2);
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: rgb(105, 155, 44);
    color: #fff !important;
  }

  .react-datepicker__month-text--keyboard-selected:hover {
    background-color: rgb(105, 155, 44);
    color: #fff !important;
  }

  .react-datepicker-popper {
    z-index: 5000;
  }
}