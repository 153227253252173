.SupplierinvoiceAttestation {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 20px 20px 20px;
  border: 2px solid rgba(255, 0, 0, 0.5);
  background-color: rgba(255, 0, 0, 0.05);
  border-radius: 10px;
  width: 400px;

  .attest-comment {
    margin-bottom: 15px;
    font-size: 12px;

    .undersigned {
      text-align: right;
      font-weight: bold;
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 10px;
  }
}