.Dropdown {
  position: relative;
  //width: 150px; // Eller en annan bredd om du vill
  font-size: 12px;

  //Disable Text Selection
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .header {
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    background-color: #fff;
    gap: 15px;

    span:first-child {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .placeholder {
      color: #6c757d;
    }
  }

  .header span {
    margin: 0;
  }
}


.Dropdown.disabled {
  pointer-events: none;
  opacity: 0.7;

  .header {
    background-color: rgba(239, 239, 239, 0.3)
  }
}


.xlnz-dropdown-options {
  max-height: 250px; /* Sätter en maxhöjd för dropdown-listan */
  overflow-y: auto; /* Gör listan scrollbar om innehållet överstiger maxhöjden */
  overflow-x: hidden;

  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  //z-index: 3000;
  white-space: nowrap; // Gör att bredden anpassas till innehållet
  width: auto; // För att förhindra att den tar full bredd
  //min-width: 100%; // Gör att dropdown-options minst är lika bred som headern
  font-size: 12px;

  .dropdown-filter {
    position: sticky;
    top: 0;
    background-color: #efefef;
    z-index: 10; /* Ser till att sökrutan ligger över alternativen när man scrollar */
    padding: 10px 5px;
    border-bottom: 1px solid #ccc;
  }

  //.dropdown-filter {
  //  position: relative;
  //  padding: 15px;
  //  border-bottom: 1px solid #ccc;
  //  background-color: #efefef;
  //}

  .dropdown-filter input {
    width: 100%;
    padding: 8px 30px 8px 8px; /* Lägger till utrymme för ikonen */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .dropdown-filter .search-icon {
    position: absolute;
    padding: 0 15px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }

  .dropdown-option {
    padding: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgba(184, 207, 105, 0.2) !important;
    }

    &.selected {
      background-color: rgba(184, 207, 105, 0.5) !important;
    }
  }

  .dropdown-clear {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}


