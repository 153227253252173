@import '../../styles/variables';

.xlnz-multi-select-container {
  position: relative;
  width: 100%;
}

.xlnz-multi-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;

  &.disabled {
    cursor: not-allowed;
    background-color: #f0f0f0;
  }

  .placeholder {
    color: #999;
  }

  .selected-options {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .chip {
      display: flex;
      align-items: center;
      color: #fff;
      background-color: $color-green;
      border-radius: 16px;
      padding: 4px 8px;
      margin-right: 4px;

      .remove-chip {
        margin-left: 4px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }

  .arrow {
    font-size: 12px;
    margin-left: 10px;
  }
}

.dropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
  margin-top: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .dropdown-option {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(184, 207, 105, 0.2) !important;
    }

    &.selected {
      background-color: rgba(184, 207, 105, 0.5) !important;
    }

    input {
      margin-right: 8px;
    }
  }
}
