.FortnoxIntegrationRedirect {
  color: white;
  max-width: 500px;
  margin: 30px auto;

  .p-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .activated {
      color: #699b2c;
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}