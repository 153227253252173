.Alert {
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  width: 500px;
  border: 1px solid #aaa;
  background-color: #fefefe;
  -webkit-box-shadow: 0px 0px 126px 0px rgba(0, 0, 0, 0.87);
  box-shadow: 0px 0px 126px 20px rgba(0, 0, 0, 0.87);
  z-index: 21042104;


  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;

    span {
      margin-left: 15px;
    }

    .faIcon {
      height: 35px;
    }
  }

  .message {
    padding: 15px 20px;
    max-height: 50vh;
    overflow-y: auto;
  }

  .footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
}