
.EditForm {
  margin: 20px 0 0 0;
  padding: 0 20px 20px 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  //max-width: 100%;
  height: fit-content;

  .columnContainer {
    display: flex;
    flex-direction: row;

    .column {
      width: 33.33%;
      margin-right: 20px;
    }

    .column:last-child {
      margin-right: 0;
    }


    @media screen and (max-width: 1000px) {
      flex-direction: column;

      .column {
        width: auto;
        margin-right: 0;
      }
    }
  }

  .formItem {
    margin-top: 15px;


    .p-inputtext {
      width: 100%;
    }

    label:first-child {
      position: relative;
      display: flex;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: 'Open Sans';
      color: #666;
      font-size: 12px !important;
    }

    .edit {
      display: none;
    }
  }

  .formItem:hover .edit {
    cursor: pointer;
    margin-left: 7px;
    display: block;
  }

  .radio-group {
    display: flex;
    flex-direction: row;

    > div:first-child {
      margin-right: 10px;
    }

    > div {
      input {
        margin-right: 5px;
      }
    }
  }

  select {
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 12px;
  }


  .button-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .divider {
      flex-grow: 1;
    }

    .left-buttons {
      display: flex;
      padding-top: 10px;
      gap: 10px;


      .p-button {
        margin-right: 15px;
      }
    }

    .right-buttons {
      display: flex;
      padding-top: 10px;
      gap: 10px;

      //.p-button, .ExtraMenu {
      //  margin-left: 15px;
      //}
    }
  }

  .TabView {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1199px) {
    .EditForm {
      margin: 10px;
    }
  }

  .headline h1 {
    margin: 15px 0 0 0;
  }

  .EditForm .p-radiobutton-label {
    padding-right: 20px;
  }

  .EditForm .p-button {
    margin-right: 7px;
  }

  .TabView {
    padding-bottom: 20px;
  }

}

.dialog {
  .EditForm {
    margin: 0;
    border: none;
    background-color: #ffffff;
    padding: 10px;

    .Grid {
      padding: 0;
    }

    input[type=submit], input[type=button] {
      margin: 0 0 0 15px;
      cursor: pointer;
    }
  }
}