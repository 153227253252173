.FileUpload {

  .p-fileupload .p-fileupload-buttonbar {
    gap: 3px;
  }

  .headerTemplate {
  }

  .emptyTemplate {
    text-align: center;
    opacity: 0.5;

    .dragndropIcon {
      font-size: 100px;
    }

    .dragAndDropInfo {
      margin-top: 20px;
      font-size: 18px;
    }
  }

}