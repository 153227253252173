.AppendixViewer {

  .previous-image {
    position: fixed;
    top: 50px;
    left: 0;
    display: flex;
    align-items: center;
    width: 30%;
    min-width: 150px;
    height: calc(100% - 50px - 15px);
    color: #aaa;
    z-index: 3100;
    cursor: pointer;
    //border: 1px solid red;

    .arrow {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  .previous-image:hover {
    .arrow {
      opacity: 1;
    }
  }

  .next-image {
    position: fixed;
    top: 50px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    width: 30%;
    min-width: 150px;
    height: calc(100% - 50px - 15px);
    color: #aaa;
    z-index: 3100;
    cursor: pointer;
    //border: 1px solid red;

    .arrow {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  .next-image:hover {
    .arrow {
      opacity: 1;
    }
  }
}
