$header-height: 120px;

.SupplierinvoiceAttest {
  color: #fff;
  //display: flex;
  //flex-direction: column;

  header {
    width: 100%;
    height: $header-height;
    //top: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

    .label {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .comment {
      padding-bottom: 10px;

      input {
        width: 300px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #000;
        font-size: 12px;
      }
    }

    .answer-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }

  .row-info {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin: 20px 10px 10px 10px;
    //max-width: 500px;
    background-color: #323639;
    border-radius: 10px;


    text-align: center;

    .row {
      padding-bottom: 20px;


      .project-label {
        font-weight: bold;
      }
    }
  }

  .document-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 80px);
    //height: 100vh;
    overflow-y: scroll;
    //margin-top: $header-height;
    padding-bottom: 200px;

    .download-button-container {
      margin: 20px 0;
    }

    .pdf-viewer-container {
      max-width: 1000px;
    }

    .error-showing-image {
      padding: 20px;
    }

    //img {
    //  display: block;
    //  width: 95vw;
    //  max-width: 980px;
    //}
  }
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}