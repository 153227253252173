.AutoComplete {
  width: 100%;

  input {
    width: 100%;
    line-height: 28px;
    border: 1px solid hsla(218, 100%, 42%, 1);
    outline: none;
    padding: 0 10px !important;
  }

  .suggestions-container {
    //position: relative;
  }

  .suggestions {
    position: absolute;
    //display: contents;
    //overflow: overlay;
    z-index: 1000000;
    background-color: white;
    border: 1px solid hsla(218, 100%, 42%, 0.5);
    list-style: none;
    margin-top: 0;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 0;
    //width: 400px;

    li {
      padding: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: normal;
      color: #000;
      line-height: normal;
      padding: 7px 5px;
    }

    li:hover {
      background-color: rgba(105, 155, 44, 0.8);
      color: #fff !important;
      cursor: pointer;
    }
  }

  .suggestions::-webkit-scrollbar-thumb {
    background-color: hsla(218, 100%, 42%, 1);
  }


  .suggestion-active {
    background-color: rgb(105, 155, 44);
    color: #fff !important;
    cursor: pointer;
  }
}

// Should be removed and changed above (line 25 now) when the DataTable is gone. /JW 2023-11-08
.XlnzReactGrid {
  .AutoComplete {
    li {
      //padding: 5px 10px;
    }
  }
}
