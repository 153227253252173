// Definiera variabler för att enkelt kunna ändra stilar globalt
$card-background: #fff;
$card-border-color: #ccc;
$card-border-radius: 3px;
$card-padding: 16px;
$card-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$card-margin: 15px 0;

$header-font-size: 1.5rem;
$subtitle-font-size: 1rem;
$subtitle-color: #666;
$footer-border-color: #eee;
$footer-padding-top: 16px;

// Huvudcontainer för kortet
.xlnz-card {
  background-color: $card-background;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;
  padding: $card-padding;
  box-shadow: $card-shadow;
  margin: $card-margin;

  // Header-stilar
  &-header {
    margin-bottom: 20px;

    .xlnz-card-title {
      margin: 0;
      font-size: $header-font-size;
      font-weight: bold;
    }

    .xlnz-card-subtitle {
      margin: 0;
      font-size: $subtitle-font-size;
      color: $subtitle-color;
    }
  }

  // Innehållscontainer
  &-content {
    margin-bottom: 16px;
  }

  // Footer-stilar
  &-footer {
    border-top: 1px solid $footer-border-color;
    padding-top: $footer-padding-top;
    text-align: right;
  }
}
