.XlnzSideBar {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100000000;

  position: fixed;

  overflow-x: hidden;
  overflow-y: auto;
  transition: right 0.5s;

  display: flex;
  align-items: center;

  max-width: 100%;

  .side-bar-content {
    background-color: #fff;
    padding: 10px;
    border: 1px solid gray;
    border-right: none;
    border-radius: 5px 0 0 5px;

    max-width: 100%;
    overflow-x: auto;
  }

  .side-bar-content.visible {
    transition: right 0.5s;
  }

  .show-button {
    width: 45px;
    height: 90px !important;
    z-index: 1000000000;
    background-color: #699b2c !important;
    font-size: 25px !important;
    color: #fff !important;
    border-radius: 5px 0 0 5px !important;
  }
}

.XlnzSideBar.visible {
  right: 0 !important;
}