.WeekplanningView {
  .weekColumn {
    //width: 280px;
    padding: 7px 0 !important;
    text-align: center !important;
  }

  .weekColumn:nth-child(even) {
    background-color: rgba(184, 207, 105, 0.2) !important;
  }

  .weekColumn:nth-child(odd) {
    background-color: rgba(184, 207, 105, 0.5) !important;
  }

  .inactive-project {
    width: 40px;
    background-color: #ddd;
    vertical-align: middle !important;
  }

  .holiday {
    background-color: rgba(128, 0, 0, 0.3) !important;
  }

  .weekend {
    background-color: rgba(128, 0, 0, 0.1) !important;
  }

  .footerColumn {
    width: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
  }
}