.FormtypeHeader1 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;

  color: #333;
  background-color: rgba(105, 155, 44, 0.30);
  padding: 3px;
  border-top: rgba(45, 74, 26) 1px solid;
  border-bottom: rgba(45, 74, 26) 1px solid;
}
