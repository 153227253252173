.Login {
  display: flex;
  background-color: #323639;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .container {
    width: 340px;
    height: 600px;
    background-color: #fff;

    padding: 100px 30px;
    border-radius: 15px;

    display: flex;
    justify-content: center;

    //.EditForm {
    //  border: none !important;
    //}

    .logo {
      max-width: 280px;
      margin-bottom: 30px;
    }

    .cred-box {
      text-align: left;
      margin: 20px auto 20px auto;
      overflow: hidden;
    }

    .info {
      margin-bottom: 20px;
      color: #666;
    }

    .button-row {
      display: flex;
      justify-content: center;
      gap: 10px
    }

    .errorMsg {
      margin-bottom: 30px;
      color: red;
      font-weight: bold;
      max-width: 100%;
    }
  }
}