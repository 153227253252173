.AppforleaveEditForm {

  .summary {
    font-size: 12px !important;

    .row {
      display: flex;
      align-items: center;
      border-top: 1px solid #ccc;
      padding: 5px 0;
      //color: red;

      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50px;
        font-weight: bold;
      }
    }

    .workday {
      color: red
    }
  }

  .days-sum {
    margin-bottom: 20px;
    > div {
      margin-top: 3px;
      font-size: 18px;
    }
  }
}