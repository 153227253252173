.EmployeeInfo {
  display: flex;
  flex-wrap: wrap;

  .type {
    margin-right: 15px;

    .type-label {
      position: relative;
      display: block;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: 'Open Sans';
      color: #666;
      font-size: 12px !important;
    }

    .employeeList {
      display: flex;
    }
  }

  .employeeList {
    display: flex;
    flex-wrap: wrap;
  }
}
