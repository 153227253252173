.PdfViewer {
  height: auto;
  padding-bottom: 10px;

  .control {
    opacity: 0;
    transition: opacity 300ms;
    gap: 5px;

    position: absolute;
    z-index: 1000;

    display: flex;
    padding: 20px;
  }

  .pageContainer {
    -webkit-box-shadow: 2px 2px 7px 0px #000000;
    box-shadow: 2px 2px 7px 0px #000000;
    margin: 10px;

    canvas {
      width: 100% !important;
      height: fit-content !important;
    }
  }

  .annotationLayer,
  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.PdfViewer:hover {
  .control {
    opacity: 1;
    transition: opacity 300ms;
  }
}

.PdfViewerModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.7);

  overflow-y: auto;
  display: flex;
  justify-content: center;

  .PdfViewerModal-container {
    //@media screen and (min-width: 900px) {
    //  width: 900px;
    //}
    @media screen and (max-width: 899px) {
      width: 100vw;
    }

    .button-bar {
      width: 100%;
      //width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      margin: 10px auto 10px auto;
      padding: 0 10px;

      .left {
        width: 700px;

        h3 {
          margin: 0;
          color: #fff;
          overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .center {
        flex-grow: 1;
      }

      .right {
        display: flex;
        justify-content: right;
        gap: 10px;
      }
    }
  }

  .portrait {
    @media screen and (min-width: 900px) {
      width: 900px;
    }
  }

  .landscape {
    @media screen and (min-width: 1200px) {
      width: 1200px;
    }
    //@media screen and (max-width: 899px) {
    //  width: 100vw;
    //}
  }
}
