.KmaView {
  //margin: 30px auto;
  //max-width: 1000px;

  //.p-card-content {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //
  //  //height: 80vh;
  //}

  //.p-editor-container {
  //  width: 100%;
  //  height: 100%;
  //
  //  .p-editor-content {
  //    height: calc(100vh - 400px);
  //  }
  //}

  .button-row {
    margin-top: 20px;
  }



  .ql-editor {
    font-family: 'Roboto', sans-serif;  /* Byt ut 'Arial' mot vilken font du vill använda */
    font-size: 12px;  /* Valfri fontstorlek */
    line-height: 1.5; /* För att göra texten mer läsbar *
  }

  .ql-container {
    font-family: 'Roboto', sans-serif;  /* Byt ut 'Arial' mot samma font som ovan */
  }

  /* Om du vill hindra användarna från att välja en annan font via inline-stil kan du lägga till: */
  .ql-editor * {
    font-family: inherit !important;
  }

}