.Grid {
  //height: 100%;
  //padding: 10px 30px;

  //.header {
  //  padding: 10px 0;
  //}

  .grid-label {
    display: flex;
    align-items: center;

    .right {
      font-size: 12px;
      text-align: right;
    }
  }

  .filter {
    //padding: 10px 0;
    display: flex;
    flex-wrap: wrap;

    .label {
      position: relative;
      display: block;

      letter-spacing: 0.5px;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: 'Open Sans';
      color: #666;
      font-size: 12px !important;
    }

    .search {
      width: 200px;
      padding: 7px;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }


  .filter {
    > div {
      padding: 5px 20px 10px 0;
    }

    > div:last-child {
      padding-right: 0px;
    }
  }

  .p-checkbox {
    vertical-align: middle;
  }


  .MobileGrid {
    //height: 500px;
    //overflow: auto;


    .mobile-row {
      background-color: white;
      padding: 20px 10px;
      border-bottom: 1px solid #2d4a1a;
      font-size: 11px;
      cursor: pointer;

      .column:not(:first-child) {
        padding-top: 10px;
      }

      .name-column {
        //display: flex;
        align-items: center;
        font-weight: bold;

        .pdf {
          padding-right: 7px;

          svg {
            font-size: 26px !important;
          }
        }
      }
    }
  }

  .redRow {
    color: red
  }

  // To make right aligned header
  .p-column-header-content {
    display: inline !important;
  }
}