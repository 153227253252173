.DocumentEditForm {

  .upload-section {

    .bar {
      display: flex;
      padding: 10px 10px 5px 10px;
      //background-color: #0b5ed7;
      gap: 10px;
    }

    .filename {
      padding: 10px 10px 5px 10px;
    }
  }
}