.RecommendVerktyg1Dialog {


  ul {
    list-style-type: circle;

    li {
      margin: 10px 0;
    }
  }
}
