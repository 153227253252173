.ImageViewer {
  height: calc(100vh - 70px);
  width: 100%;

  img {
    -webkit-box-shadow: 2px 2px 7px 0px #000000;
    box-shadow: 2px 2px 7px 0px #000000;

    @media screen and (min-width: 1000px) {
      max-width: 1000px;
      max-height: calc(100vh - 70px);
      margin: 0 auto;

      display: block;
      width: auto;
      height: auto;
    }
    @media screen and (max-width: 899px) {
      width: 100vw;
    }

    transition: opacity 0.2s;
  }

  .annotationLayer,
  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.ImageViewerModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.7);

  //overflow-y: auto;
  display: flex;
  justify-content: center;

  .ImageViewerModal-container {

    .button-bar {

      @media screen and (min-width: 1000px) {
        width: 1000px;
      }
      @media screen and (max-width: 899px) {
        width: 100vw;
        padding: 0 10px;
      }

      //width: 100%;
      //width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      margin: 10px auto 10px auto;

      .left {
        flex-grow: 1;

        h3 {
          margin: 0;
          color: #fff;
          overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .right {
        display: flex;
        justify-content: right;
        gap: 10px !important;
      }
    }
  }
}
