.MonthoverviewView {
  min-width: 1600px;

  .dayColumn {
    width: 44px;
    padding: 0 !important;

    .p-inputnumber-input {
      text-align: center;
    }
  }
}