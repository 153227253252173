.AddressQuery {
  display: flex;
  align-items: center;
  width: 100%;

  .addressSearch {
    flex-grow: 1;
  }

  .map-marker {
    padding: 0 10px;
    cursor: pointer;
  }
}

.AddressDialog {


  .zip-and-city {
    display: flex;
    gap: 10px;
  }
}