.FormtypeHeader2 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  flex-basis: 100%;
  //color: #666;

  color: #333;
  background-color: rgba(105, 155, 44, 0.10);
  padding: 3px;
  border-top: rgba(45, 74, 26) 1px solid;
  border-bottom: rgba(45, 74, 26) 1px solid;
}

//.FormtypeHeader2:first-child {
//  margin-top: 0;
//}
