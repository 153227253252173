.AvatarWithHours {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  margin-right: 10px;
  line-height: 25px;

  $borderRadius: 10px;

  .leftHours {
    padding: 0 5px;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    background-color: #2d4a1a;
    color: #fff;
  }

  .name {
    min-width: 30px;
    background-color: #ddd;
    text-align: center;
    padding: 0 5px;
    white-space: nowrap;

  }

  .name-notarized {
    //background-color: red;
    background-color: #fdd;
  }

  .rightHours {
    padding: 0 5px;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    background-color: #699b2c;
    color: #fff;
  }

  //.hoursBadge {
  //  $hours-size: 20px;
  //  height: $hours-size;
  //  width: $hours-size+7;
  //  text-align: center;
  //
  //  .initials {
  //    font-size: 12px; /* 50% of parent */
  //    line-height: 1;
  //    position: relative;
  //    top: -4px;
  //  }
  //}
}

//.fixedHours {
//  position: absolute;
//  top: 23px;
//  left: -5px;
//  border-radius: 40%;
//  background-color: #2d4a1a;
//  color: #fff;
//}
//
//.costplusHours {
//  position: absolute;
//  top: 23px;
//  right: -6px;
//  border-radius: 40%;
//  color: #000 !important;
//  background-color: #b8cf69;
//}

.unNotarized {
  //position: absolute;
  //top: -12px;
  //right: -6px;
  margin-top: -12px;
  margin-left: -8px;
  border-radius: 40%;
  color: #000 !important;
  //background-color: #b8cf69;
}