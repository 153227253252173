@import "/src/styles/Mixin";

.DayplanningView {
  .filter {
    display: flex;

    .label {
      @include field-label;
    }

    .search {
      width: 200px;
    }
  }


  .filter {
    //margin-bottom: 5px;
    > div {
      padding-right: 20px;
    }

    > div:last-child {
      padding-right: 0px;
    }
  }

  //.grid-container {
  //  height: calc(100dvh - 200px);
  //  overflow: auto;
  //}



}