.xlnz-menu {
  z-index: 10000;
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  font-size: 12px;
  position: absolute;
  opacity: 0; // Lägg till för att menyn är osynlig initialt men fortfarande mätbar
  visibility: hidden; // Menyn är osynlig men fortfarande i layoutflödet

  &.visible {
    opacity: 1; // Gör menyn synlig
    visibility: visible;
  }

  .xlnz-menu-item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    position: relative; // För att positionera undermenyer relativt sina föräldrarmenyer

    /* Disable text selection */
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */

    &:hover {
      background-color: #f0f0f0;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      font-size: 16px;
      margin-right: 10px;
    }

    &-arrow {
      margin-left: auto;
      padding-left: 10px;
      font-size: 0.8em;
    }

    .xlnz-submenu {
      position: absolute;
      top: 0;
      left: 100%;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 8px 0;
      z-index: 10000;
      //opacity: 0; // Dold undermeny initialt
      //visibility: hidden; // Undermeny är osynlig men fortfarande mätbar
      display: block; // Viktigt att hålla display block så att det kan mätas

      &.visible {
        opacity: 1; // Gör undermenyn synlig
        visibility: visible;
      }
    }
  }

  .xlnz-menu-item-disabled {
    color: #ccc
  }

  .xlnz-menu-item-disabled:hover {
    background-color: transparent;

  }
}
