.Button {
  //padding-right: 10px;

  // Disable Text Selection
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    padding: 7px 14px;
    border: none;
    color: #000;
    background-color: #ddd;
    height: 30px;

    div {
      white-space: nowrap;
    }
  }

  button:hover {
    opacity: 0.8;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .faIcon {

  }

  .remove-button {
    background-color: rgba(255, 0, 0, 0.2);
  }

}


.Button:last-child {
  padding-right: 0;
}

.RightSideBarButton {
  position: fixed;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 0;

  width: 45px;
  height: 90px !important;


  z-index: 1000000000;

  background-color: #699b2c !important;
  font-size: 25px !important;
  color: #fff !important;
  border-radius: 5px 0 0 5px !important;
}