.Dashboard {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 100px !important;

  min-width: 900px;

  .dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .filter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;

      > div {
        padding: 0;
      }

      .costcenter-filter {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 20px
      }
    }


    .card {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: #fff;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 40px;
    }
  }
}