.Confirm {
  position: fixed;
  top: 30%;
  left: calc(50%);
  transform: translateX(-50%);
  width: 400px;
  max-width: 95%;

  border: 1px solid #aaa;
  background-color: #fefefe;
  -webkit-box-shadow: 0px 0px 126px 0px rgba(0, 0, 0, 0.87);
  box-shadow: 0px 0px 126px 20px rgba(0, 0, 0, 0.87);
  z-index: 9999999;

  &:focus {
    outline: none;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;

    span {
      margin-left: 15px;
    }
  }

  .message {
    padding: 15px 20px;
  }

  .footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 20px;
    text-align: right;
  }
}