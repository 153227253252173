.App {
  position: fixed;
  width: 100%;
  //max-width: 1200px;
  height: 100%;
  overflow: auto;

  //-webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.87);
  //box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.87);
  z-index: 0;

  >.container {

    //max-width: 1600px;
    height: 100%;
    overflow-y: auto;
    background-color: #f9f9f9;

    >.content {
      position: fixed;
      top: 64px;
      width: 100%;
      height: calc(100dvh - 64px);
      padding: 0 20px;
      overflow-y: auto;
    }
  }

  @import "components/Grid/Grid";
}

.AppContainer {
  //max-width: 1600px;
  //margin: 0 auto;
  .form-container {
    display: flex;
  }

  @media screen and (min-width: 1000px) {
    .EditForm {
      width: 1200px;
    }
  }
}

.p-component, .p-inputtext, .p-link {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
}


.p-dropdown .p-dropdown-label {
  line-height: 22px !important;
  padding: 3px 7px;
}

.pi {
  font-size: 10px;
}

.p-dropdown .p-dropdown-clear-icon {
  line-height: 16px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #fff !important;
}

.p-sortable-column-icon {
  display: none !important;
}

a:visited {
  color: inherit;
}



.alignLeft {
  text-align: left !important;
}

.alignRight {
  text-align: right !important;
}


ul {
  list-style-type: none;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
}


textarea {
  font: 400 13.3333px Arial;
  font-family: 'Roboto', sans-serif !important;
}

.btn {
  padding: 7px;
}


.excel-icon-btn {
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background: url(./resources/icons/excel.svg) no-repeat top left;
}

.pdf-icon-btn {
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background: url(./resources/icons/pdf.svg) no-repeat top left;
}

.p-table-reorderablerow-handle {
  cursor: pointer;
}

.grid-add-button {
  color: #4c4c4c;
  background-color: #eee;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: bold;
}

@import "./styles/InfoTable";
@import "./styles/Tooltip";

//@import "./styles/Dialog";
//@import "./styles/Dropdown";
//@import "./styles/Checkbox";
//@import "./styles/DataTable";
//@import "./styles/DatePicker";
//@import "./styles/FileUpload";
//@import "./styles/MultiSelect";
//@import "./styles/TabView";
//@import "./styles/Menu";
//@import "./styles/Paginator";
//@import "./styles/InputSwitch";