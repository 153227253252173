.FormItemRowGrid {

  .headline {
    font-weight: bold;
  }

  .only-total {
    font-style: italic;
    //opacity: 0.6;
    color: #999;
  }

  .footer {
    display: flex;
    padding-top: 15px;

    .middle {
      flex-grow: 1;
      text-align: center;
    }

    .right {

      table {
        font-size: 12px;

        td:first-child {
          padding-right: 20px;
        }

        td:last-child {
          text-align: right;
        }

        .vat-detail {
          color: #999;
          font-style: italic;
        }

        .to-pay {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}


.grid-footer {
  display: flex;
  padding-top: 15px;

  .middle {
    flex-grow: 1;
    text-align: center;
  }

  .right {

    table {
      font-size: 12px;

      td:first-child {
        padding-right: 20px;
      }

      td:last-child {
        text-align: right;
      }

      .vat-detail {
        color: #999;
        font-style: italic;
      }

      .to-pay {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}