.Header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  //z-index: 100; Messes up sidebar in supplierinvoice.

  .new-version {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;

    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: bold;

    > div {
      margin-bottom: 10px;
    }
  }
}

