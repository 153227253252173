// Prevent pull-down-to-refresh of mobile chrome,safari
html,
body {
  overflow: hidden;
  overscroll-behavior: none;
}

html {
  position: fixed;
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;
    background-color: #525659;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px;

    #root {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    h1 {
      font-family: 'Open Sans';
      font-size: 24px;
      color: #444;
    }

    h2 {
      font-family: 'Open Sans';
      font-size: 20px;
      color: #444;
    }

    h3 {
      font-family: 'Open Sans';
      font-size: 16px;
      color: #444;

      display: flex;
      gap: 10px;
      align-items: center;
    }

    input[type='radio'] {
      accent-color: #699b2c;
    }

    select {
      font-size: 12px;
    }


    input[type=date] {
      font-size: 12px !important;
      font-family: 'Roboto', sans-serif !important;
      height: 25px;
    }

    input:focus, textarea:focus {
      outline: none; /*or outline-color:#FFFFFF; if the first doesn't work*/
      border: 1px solid #48A521;
      -webkit-box-shadow: 0px 0px 2px 0px #699b2c;
      box-shadow: 0px 0px 2px 0px #699b2c;
    }

    /* Hide Arrows From Input Number */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    input, .textarea {
      padding: 7px;
      border: 1px solid #cccccc;
      border-radius: 3px;
      font-size: 12px;
    }


    .textarea {
      display: block;
      width: 100%;
      overflow: hidden;
      resize: both;
      min-height: 40px;
      line-height: 20px;
    }

  }
}

.xlnz-table {
  td, th {
    padding-right: 10px;
  }

  td:last-child, th:last-child {
    padding-right: 0;
  }
}

//Disable Text Selection
.no-text-selection {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

* {
  box-sizing: border-box;
}