.TimeregEmployeePerWeek {


  .DayGridTemplate {
    display: flex;
    justify-content: flex-end;

    .unnotarized-warning {
      padding-right: 5px;
      //margin-top: -12px;
      //margin-left: 8px;
      border-radius: 40%;
      color: #000 !important;
    }
  }

  .holiday {
    background-color: rgba(128, 0, 0, 0.3) !important;
  }

  .weekend {
    background-color: rgba(128, 0, 0, 0.1) !important;
  }
}