.xlnz-file-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;

  .xlnz-file-upload__header {
    display: flex;
    gap: 10px
  }

  &.drag-active {
    background-color: #e0e0e0;
    border-color: #699b2c;
  }

  &-header {
    font-weight: bold;
    margin-bottom: 15px;
  }

  &-content {
    margin-bottom: 10px;
  }

  //&-choose {
  //  background-color: #007bff;
  //  color: white;
  //  border: none;
  //  padding: 10px 20px;
  //  border-radius: 5px;
  //  cursor: pointer;
  //  font-size: 16px;
  //
  //  &:hover {
  //    background-color: #0056b3;
  //  }
  //}

  &-files {
    margin-top: 10px;

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  &-empty {
    font-size: 14px;
    color: #999;
  }


  .emptyTemplate {
    text-align: center;
    opacity: 0.5;

    .dragndropIcon {
      font-size: 100px;
    }

    .dragAndDropInfo {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}
