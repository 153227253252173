.ContextMenuItem {
  display: flex;
  cursor: pointer;
  padding: 10px 10px;
  align-items: center;

  &:hover {
    background-color: #eeeeee;
  }

  .icon {
    padding: 5px;
    font-size: 18px;
    width: 32px;
    text-align: center;
  }

  .label {
    padding: 5px;
    flex-grow: 1;
  }

  .right-arrow {
    padding: 5px;
    font-size: 12px;
    width: 32px;
    text-align: center;
  }
}