// Variabler för enkel justering av färger och storlekar
$input-padding: 8px;
$input-border-color: #ccc;
$input-border-radius: 4px;
//$suggestions-border-color: #ccc;
$suggestions-background-color: #fff;
$no-suggestion-color: #999;
$input-font-size: 12px;
$max-suggestions-height: 150px;

// Huvudcontainer för autocomplete
.xlnz-autocomplete {
  position: relative;
  width: 100%;
  max-width: 300px;

  &-input {
    width: 100%;
    padding: $input-padding;
    font-size: $input-font-size;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    box-sizing: border-box;

    &:disabled {
      background-color: #f0f0f0;
      cursor: not-allowed;
    }
  }

  &-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: $suggestions-background-color;
    //border: 1px solid $suggestions-border-color;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    max-height: $max-suggestions-height;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  &-suggestion {
    padding: $input-padding;
    cursor: pointer;
    font-size: $input-font-size;


    &:hover {
      background-color: rgba(184, 207, 105, 0.2);
    }

    &.active {
      background-color: rgba(184, 207, 105, 0.5);
      //color: #fff !important;
    }
  }

  &-no-suggestion {
    padding: $input-padding;
    color: $no-suggestion-color;
    text-align: center;
  }
}
