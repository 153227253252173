.SupplierinvoiceImageSidebar {
  width: 100%;

  .button-row {
    display: flex;
    padding: 5px 0 10px 10px;
    border-bottom: 1px solid #ddd;
    align-items: flex-end;

    .left-buttons, .right-buttons {
      display: flex;
      gap: 10px
    }

    .divider {
      flex-grow: 1
    }
  }

  .SupplierinvoiceImageSidebar-container {
    height: calc(100vh - 100px);
    overflow: auto;
    overflow-x: hidden;
  }
}