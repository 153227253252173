.info-table {
  border-spacing: 0;
  border-collapse: separate;
  font-size: 12px;

  th, td {
    text-align: left;
    padding: 7px 7px;
  }

  //th:first-child, td:first-child {
  //  padding-left: 0;
  //}
  //
  //th:last-child, td:last-child {
  //  padding-right: 0;
  //}

  th {
    border-bottom: 1px solid #dee2e6;
  }

  td {
    border-bottom: 1px solid #dee2e6;
  }


  .table-padding::before
  {
    content: '';
    display: block;
    height: 15px;

  }
}