$BLOCK_HEIGHT: 20px;
$BLOCK_GAP: 3px;

.scheduler {
  background: white;
  //border-radius: 8px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  //padding: 20px;
}

.scheduler__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.scheduler__navigation {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #1e40af;
}

.nav-button:hover {
  color: #1e3a8a;
}

.current-week {
  font-weight: 500;
  color: #1e3a8a;
}

.scheduler__view-modes {
  display: flex;
  gap: 2px;
  background: #f1f5f9;
  padding: 2px;
  border-radius: 6px;
}

.view-button {
  padding: 6px 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #64748b;
  border-radius: 4px;
}

.view-button.active {
  background: white;
  color: #1e3a8a;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.scheduler__grid {
  border: 1px solid #e2e8f0;
  border-radius: 6px;

  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.scheduler__header {
  position: sticky;
  top: 0;
  display: flex;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.scheduler__employee-column {
  width: 200px;
  flex-shrink: 0;
  //padding: 12px;
  //font-weight: 500;
  //color: #475569;
  //border-right: 1px solid #e2e8f0;
}

.scheduler__day-column {
  flex: 1;
  //padding: 8px;
  line-height: 28px;
  text-align: center;
  border-right: 1px solid #e2e8f0;
  //min-width: 100px;
  font-weight: bold;
}

//.day-name {
//  color: #64748b;
//  font-size: 12px;
//  text-transform: uppercase;
//}
//
//.day-number {
//  color: #1e3a8a;
//  font-weight: 500;
//  font-size: 14px;
//  margin-top: 2px;
//}

.scheduler__body {
  background: white;
}

.scheduler__row {
  display: flex;
  border-bottom: 1px solid #e2e8f0;
}

.scheduler__employee-name {
  width: 200px;
  flex-shrink: 0;
  //padding: 7px;
  line-height: $BLOCK_HEIGHT+$BLOCK_GAP*2;
  padding-left: 5px;

  border-right: 1px solid #e2e8f0;
  background: white;
  color: #1e293b;
}

.scheduler__cell {
  flex: 1;
  //min-height: 30px;
  //height: 120px;
  border-right: 1px solid #e2e8f0;
  position: relative;
  //min-width: 100px;
  //background-color: lightblue;

  .new-project-button-container {
    position: absolute;
    //bottom: 0px;
    //transform: translateY(50%);
    width: 100%;
    //height: $BLOCK_HEIGHT+$BLOCK_GAP*2;
    //height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    //text-align: center;

    //background-color: rgba(105, 155, 44, 0.4);

    .new-project-button {
      //position: absolute;
      //left: 50%;
      //transform: translateX(-50%);
      width: 30px;
      height: 20px;
      //margin-top: 2px;

      display: flex;
      align-items: center;
      justify-content: center;
      //bottom: 3px;
      border: 1px solid gray;
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;

      display: none;
      align-items: center;
      justify-content: center;
      //background-color: #699b2c;
      background-color: rgba(105, 155, 44, 0.7);

      color: #fff;
    }
  }

  .new-project-button-container:hover {
    .new-project-button {
      display: flex;
    }
  }

  .project-block {
    position: absolute;
    left: 0px;
    //right: 0px;
    //height: 24px;
    line-height: 20px;
    border-radius: 4px;
    //margin-top: 2px;
    //padding: 4px 8px;
    //display: flex;
    //align-items: center;
    //justify-content: left;
    padding-left: 13px;
    font-size: 12px;
    color: white;
    cursor: move;
    user-select: none;
    z-index: 1;
    //overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .project-block:active {
    cursor: grabbing;
  }

  .project-block__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .project-block__resize {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 7px;
    cursor: col-resize;
  }

  .project-block__resize--start {
    left: 0px;
    border-radius: 8px 0 0 8px;
  }

  .project-block__resize--end {
    right: 0;
    border-radius: 0 8px 8px 0;
  }
}

.scheduler__drag-over {
  box-shadow: 0 0 10px rgba(105, 155, 44, 0.7);
  animation: scheduler__pulseGlow 1.5s infinite ease-in-out;
  background-color: #efefef;
}

@keyframes scheduler__pulseGlow {
  0%, 100% {
    box-shadow: 0 0 10px rgba(105, 155, 44, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(105, 155, 44, 0.9);
  }
}

.holiday {
  background-color: rgba(128, 0, 0, 0.1) !important;
}