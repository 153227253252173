.DesktopHeader {
  //max-width: 1600px;
  margin: 0 auto;
  text-align: left;
  background-color: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #2d4a1a;
  //font-weight: bold !important;
  //padding: 0 30px 0 0;
  border-bottom: 1px solid #151c15;

  //font-weight: 400;
  //font-style: normal;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;

  .menu-button {
    background-color: #2d4a1a;
    padding: 0 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;

    // Disable Text Selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    > div {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: normal !important;

      svg {
        font-size: 24px;
        padding-right: 10px;
      }
    }
  }

  .menu-button:hover {
    background-color: #151c15;
  }


  #logo {
    width: 150px;
    margin-left: 40px;

    // Disable Text Selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  @media only screen and (max-width: 1000px) {
    #logo {
      width: 100px;
      margin-left: 20px;
    }
  }


  #leaf {
    height: 80%;
    margin-left: 100px;
    align-self: end;

    // Disable Text Selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .divider {
    flex-grow: 1;
  }

  .user-button {
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    text-align: right;
    border-left: 1px solid #666;

    > div {
      display: flex;
      flex-direction: column;
    }

    span {
      white-space: nowrap;

      @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: calc(100vw - 170px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .header-button-clickable:hover {
    color: #151c15;
    height: 64px;
    border-top: 3px solid #151c15;
    border-bottom: 3px solid transparent;
    cursor: pointer;
  }


  .help-button {
    background-color: #48575e;
    padding: 0 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // Disable Text Selection
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    > div {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: normal !important;

      svg {
        font-size: 24px;
        padding-right: 10px;
      }
    }
  }

  .help-button:hover {
    background-color: #151c15;
  }
}

.ContextMenuItem {
  padding: 3px 10px;
}

// To avoid the menu flipping to the other side on really small devices.
.p-tieredmenu .p-menuitem-active > .p-submenu-list-flipped {
  left: 100%;
}