
.TabView {

  header {
    display: flex;
    flex-direction: row;
    //gap: 10px;

    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #6c757d;

    > div {
      cursor: pointer;
      padding: 12px 15px;

      border: solid;
      border-width: 1px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .active {
      border-width: 1px;
      border-color: #dee2e6 #dee2e6 #fff;
      color: #2d4a1a;
    }

    > div:not(.active) {
      border-width: 1px;
      border-color: #fff #fff #dee2e6
    }

    //No selectable text
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}