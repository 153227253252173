.TooltipContent {
  display: inherit;
}

.tooltip {
  position: fixed;
  z-index: 1000000000;

  font-size: 12px;
  background-color: #666;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  //white-space: pre;

  -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.3s; /* Firefox < 16 */
  -ms-animation: fadein 0.3s; /* Internet Explorer */
  -o-animation: fadein 0.3s; /* Opera < 12.1 */
  animation: fadein 0.3s;

  table {
    td {
      text-align: left;
      line-height: 16px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}