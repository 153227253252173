
@mixin field-label {
  position: relative;
  display: block;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: 'Open Sans';
  color: #666;
  font-size: 12px !important;
}