$header-height: 80px;

.TenderAnswer {
  color: #fff;

  header {
    width: 100%;
    height: $header-height;
    //top: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

    .label {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .answer-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }

  .document-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 80px);
    //height: 100vh;
    overflow-y: scroll;
    //margin-top: $header-height;
    padding-bottom: 200px;

    .loading-image {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
    }

    .download-button-container {
      margin: 20px 0;
    }

    img {
      display: block;
      width: 95vw;
      max-width: 980px;
    }
  }
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}